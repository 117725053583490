import React, { useEffect, useState } from "react";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxDialog2 from "../../../../../components/SbuxDialog2";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SbuxAutocomplete from "../../../../../components/SbuxAutoComplete";
import { getDays, getTime } from "../../../../../utils/timeUtil";
import {
  Checkbox,
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import moment from "moment";
import useStyles from "../../styles";

const MainHoursConfigTableRow = ({
  row,
  handleDeleteRowClick,
  handle24HoursClick,
}) => {
  const classes = useStyles();
  const [start, setStart] = useState(row.start);
  const [end, setEnd] = useState(row.end);
  const [day, setDay] = useState(row.day);
  const [checked, setChecked] = useState(
    row.hasOwnProperty("isOpenFor24Hours") ? row.isOpenFor24Hours : false
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const toggleDeleteDialogOpen = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleDeleteCurrentRow = () => {
    handleDeleteRowClick(row);
  };

  const handleDayChange = (day) => {
    setDay(day.value);
    row.day = day.value;
  };

  const handleOpen24Hours = (newValue) => {
    setChecked(newValue.target.checked);
    row.isOpenFor24Hours = newValue.target.checked;
    handle24HoursClick(row);
  };

  const handleTimeChange = (value, isStart) => {
    if (isStart) {
      setStart(moment(value, ["hh:mm A"]).format("HH:mm"));
      row.start = moment(value, ["hh:mm A"]).format("HH:mm");
    } else {
      setEnd(moment(value, ["hh:mm A"]).format("HH:mm"));
      row.end = moment(value, ["hh:mm A"]).format("HH:mm");
    }

    if (
      value &&
      value === "12:00 AM" &&
      row.start === "00:00" &&
      row.end === "00:00"
    ) {
      setChecked(true);
      row.isOpenFor24Hours = true;
    }
  };

  const dialogConfig = {
    title: "Delete",
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell padding={"0px"}>
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={toggleDeleteDialogOpen}
            className={classes.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </SbuxStyledTableCell>
        <SbuxStyledTableCell padding={"0px"}>
          <SbuxAutocomplete
            id="days"
            options={getDays()}
            type="text"
            value={day}
            handleDayChange={(newValue) => handleDayChange(newValue)}
            handleTimeChange={null}
          />
        </SbuxStyledTableCell>
        <SbuxStyledTableCell padding={"0px"}>
          <SbuxAutocomplete
            options={getTime(null)}
            type="time"
            value={moment(start, ["HH:mm"]).format("hh:mm A")}
            handleDayChange={null}
            handleTimeChange={(newValue) => handleTimeChange(newValue, true)}
            compareTo="endTime"
            compareToValue={moment(end, ["HH:mm"]).format("hh:mm A")}
            isOpen24Hours={checked}
          />
        </SbuxStyledTableCell>
        <SbuxStyledTableCell padding={"0px"}>
          <SbuxAutocomplete
            options={getTime(end)}
            type="time"
            value={moment(end, ["HH:mm"]).format("hh:mm A")}
            handleDayChange={null}
            handleTimeChange={(newValue) => handleTimeChange(newValue, false)}
            compareTo="startTime"
            compareToValue={moment(start, ["HH:mm"]).format("hh:mm A")}
            isOpen24Hours={checked}
          />
        </SbuxStyledTableCell>

        <SbuxStyledTableCell padding={"0px"}>
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            disableFocusRipple
            disableRipple
            checked={checked}
            onChange={handleOpen24Hours}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 22 },
              color: "#006241",
              "&.Mui-checked": {
                color: "#006241",
              },
            }}
          />
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
      {deleteDialogOpen && (
        <SbuxDialog2
          open={deleteDialogOpen}
          config={dialogConfig}
          handleOnClose={toggleDeleteDialogOpen}
        >
          <DialogContent dividers>
            <Typography variant="h5">{`Are you sure you want to delete?`}</Typography>
          </DialogContent>

          <DialogActions>
            <Box className="actionContainer">
              <Button
                variant="contained"
                size="medium"
                onClick={handleDeleteCurrentRow}
              >
                Okay
              </Button>
              <Button
                variant="outlined"
                size="medium"
                onClick={toggleDeleteDialogOpen}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </SbuxDialog2>
      )}
    </>
  );
};

export default MainHoursConfigTableRow;
