import { useCallback, useState, useEffect } from "react";
import SbuxGlobalNav from "../components/SbuxGlobalNav";
import SbuxIdleTimer from "../components/SbuxIdleTimer";
import SbuxSettings from "../components/SbuxSettings";
import IconButton from "@mui/material/IconButton";
import SbuxSelect from "../components/SbuxSelect";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import { signOut } from "../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedInstance } from "../services/instance";
import { getCcpInitializedStatus, getCcpContactStatus } from "../services/ccp";
import { updateCotactRecordingStateAction } from "../services/screenRecording";
import classNames from "classnames";
import useStyles from "./styles";
import {
  Paper,
  Box,
  Button,
  Typography,
  AppBar,
  Container,
  Toolbar,
  SvgIcon,
} from "@mui/material";
import useTextTranslate from "../features/content/hooks/useTextTranslate";
import ScreenRecordingButtons from "../features/content/screenRecording";
import {
  CONTACT_CONNECTED_STATUS,
  VAR_IS_RECORDING,
  CONTACT_RECORDING_START,
  VAR_RECORDING_CONTROLS,
  NAVBAR_AGENT_SET_OFFLINE,
  NAVBAR_SIGNOUT_BTN_CLICKED,
} from "../constants";
import { setAgentStateOffline } from "../utils/agentStateChangeUtil";
import { screenRecordingActions } from "../slices/screenRecordingSlice";
import { ReactComponent as SbuxLogo } from "../assets/image.svg";
import NavDrawer from "./NavDrawer";

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    auth: { cognitoUserId },
    userInstance: { connectedInstance, instances },
    ccp: {
      ccpContactStatus,
      ccpContact: { contactAttributes, contactData },
    },
    tabs: { tabsStabus },
    screenRecording: {
      contactRecordingState,
      loading: contactRecordingLoadingState,
      error: contactRecordingStateError,
    },
  } = useSelector((state) => state);

  const [openSettings, setOpenSettings] = useState(false);
  const [defaultInstance, setDefaultInstance] = useState(null);
  const [changeInstance, setChangeInstance] = useState(null);
  const instanceUrl = connectedInstance ? connectedInstance.instanceUrl : null;
  const signOutTranslatedText = useTextTranslate("Sign Out");
  const isRecordingControlsEnabled =
    connectedInstance?.appConfig?.isRecordingControlsEnabled ?? false;

  const isStopRecordingFeatureImplementedInAWS =
    connectedInstance?.appConfig?.isStopRecordingFeatureImplementedInAWS ??
    false;

  const areScreenRecordingControlsEnabledForCallType =
    (contactAttributes?.[VAR_RECORDING_CONTROLS]?.value ?? "true") === "true" ??
    true;

  const updateInitialContactRecordingState = (isRecording) => {
    dispatch(
      screenRecordingActions.resetContactRecordingStateAction(
        isRecording ? CONTACT_RECORDING_START : null
      )
    );
  };

  useEffect(() => {
    const isRecording =
      contactAttributes?.[VAR_IS_RECORDING]?.value === "true" ?? false;
    updateInitialContactRecordingState(isRecording);
  }, [contactAttributes]);

  useEffect(() => {
    connectedInstance &&
      connectedInstance.instanceId &&
      setDefaultInstance({
        name: connectedInstance.friendlyName,
        value: connectedInstance.instanceId,
      });
  }, [connectedInstance]);

  const handleSignOut = async (evt) => {
    evt?.preventDefault();
    await setAgentStateOffline(NAVBAR_SIGNOUT_BTN_CLICKED);
    await dispatch(
      signOut({
        instanceUrl,
        component: "NavBar",
        userEmail: cognitoUserId,
      })
    );
  };

  const handleSettings = () => {
    !openSettings && setOpenSettings(true);
  };

  const toggleHandleSettings = () => {
    setOpenSettings(!openSettings);
  };

  const menuItems = () => {
    if (!instances || instances.length === 0) {
      return [];
    }
    const sortedInstances = [...instances].sort((a, b) =>
      a.friendlyName.localeCompare(b.friendlyName)
    );

    return sortedInstances.map((instance) => {
      return {
        name: instance.friendlyName,
        value: instance.instanceId,
      };
    });
  };

  const actionContent = () => (
    <>
      <SbuxSelect
        disabled={
          tabsStabus === "disabled" ||
          instances.length === 1 ||
          (ccpContactStatus === null || ccpContactStatus === "closed"
            ? false
            : true)
        }
        label="Instance"
        menuItems={menuItems()}
        defaultValue={defaultInstance.value}
        value={changeInstance ? changeInstance.value : defaultInstance.value}
        handleSelectChange={async (value) => {
          await setAgentStateOffline(NAVBAR_AGENT_SET_OFFLINE);
          const changeInstance = instances.find((f) => f.instanceId === value);
          if (changeInstance) {
            setChangeInstance({
              name: changeInstance.friendlyName,
              value: changeInstance.instanceId,
            });
            window.connect && window.connect.core.terminate();
            await dispatch(getConnectedInstance(changeInstance));
            await dispatch(getCcpInitializedStatus(false));
            await dispatch(getCcpContactStatus(null));
          }
        }}
      />

      <Typography variant="h5">{cognitoUserId}</Typography>

      <Button
        onClick={handleSignOut}
        variant="outlined"
        size="medium"
      >
        {signOutTranslatedText}
      </Button>
    </>
  );

  const handleRecordingButtonTrigger = async ({
    action,
    contactId,
    initialContactId,
  }) => {
    await dispatch(
      updateCotactRecordingStateAction({ action, contactId, initialContactId })
    );
  };

  const hideErrorDialog = async () => {
    await new Promise((r) => setTimeout(r, 5000));
    dispatch(screenRecordingActions.resetError());
  };

  return (
    <>
      {defaultInstance && (
        <AppBar position="static">
          <Toolbar>
            <SvgIcon className={classes.sbuxLogo}>
              <SbuxLogo />
            </SvgIcon>

            <Typography
              variant="h5"
              component="div"
              className={classes.appName}
            >
              {`Connect Desktop`}
            </Typography>

            <Box className={classes.recordingButtons}>
              {areScreenRecordingControlsEnabledForCallType &&
                isRecordingControlsEnabled && (
                  <ScreenRecordingButtons
                    handleRecordingButtonTrigger={handleRecordingButtonTrigger}
                    isStopRecordingFeatureImplementedInAWS={
                      isStopRecordingFeatureImplementedInAWS
                    }
                    contactAttributes={contactAttributes}
                    contactData={contactData}
                    ccpContactStatus={ccpContactStatus}
                    contactRecordingState={contactRecordingState}
                    loading={contactRecordingLoadingState}
                    error={contactRecordingStateError}
                    hideErrorDialog={hideErrorDialog}
                  />
                )}
            </Box>

            <Box className={classes.toolbarActions}>
              {actionContent()}

              <IconButton
                disableFocusRipple
                disableRipple
                disabled={tabsStabus === "disabled" ? true : false}
                onClick={handleSettings}
              >
                <SettingsIcon sx={{ fontSize: 30 }} />
              </IconButton>

              <NavDrawer cognitoUserId={cognitoUserId}>
                {actionContent()}
              </NavDrawer>
            </Box>

            <SbuxIdleTimer />

            {openSettings && (
              <SbuxSettings
                openSettings={openSettings}
                toggleHandleSettings={toggleHandleSettings}
              />
            )}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default Navbar;
