import { useState } from "react";
import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxSnackbars from "../../../../components/SbuxSnackbars";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import ClosureMsgAdd from "../table/closureMsg/ClosureMsgAdd";
import ClosureMsgEdit from "../table/closureMsg/ClosureMsgEdit";
import ClosureSingleDeleteDialog from "./ClosureSingleDeleteDialog";
import { closureMsgRepeatPattern } from "../utils";
import { createOrUpdateClosureMsg } from "../../../../services/closureMsg";
import { Box, Button, DialogContent, DialogActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { SNACK_BARS_SEVERITY_ERROR } from "../../../../constants";
import moment from "moment-timezone";
import useStyles from "../styles";

const ClosureMsgDialog = ({
  title,
  open,
  row,
  toggleDialogOpen,
  business,
  languages,
  isAddNew,
  globalConfigUi,
  isSingleInstance,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [closureMsgRow, setClosureMsgRow] = useState(row);
  const [isDelete, setIsDelete] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isAudioChanged, setIsAudioChanged] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleDeleteOnClick = () => {
    setIsDelete((prevState) => !prevState);
  };

  const handleSave = async () => {
    let result,
      repeatPattern = null;
    repeatPattern =
      closureMsgRow && closureMsgRow.type === "Repeat"
        ? closureMsgRepeatPattern(closureMsgRow, isAddNew)
        : null;
    //No date option selected
    if (closureMsgRow.isReccuringOption2Selected) {
      delete repeatPattern["until"];
    }

    if (isAddNew) {
      result = repeatPattern
        ? {
            hoursCode: Number(closureMsgRow.hoursCode),
            groupName: closureMsgRow.groupName,
            description: closureMsgRow.description,
            type: closureMsgRow.type,
            category: closureMsgRow.category,
            startDate: moment(closureMsgRow.startDate).format("YYYY-MM-DD"),
            startTime: moment(closureMsgRow.startTime, "hh:mm A").format(
              "hh:mm A"
            ),
            endDate: moment(closureMsgRow.endDate).format("YYYY-MM-DD"),
            endTime: moment(closureMsgRow.endTime, "hh:mm A").format("hh:mm A"),
            startTimeZone: closureMsgRow.startTimeZone,
            endTimeZone: closureMsgRow.endTimeZone,
            ...closureMsgRow.languageMessage,
            repeatPattern: repeatPattern,
          }
        : {
            hoursCode: Number(closureMsgRow.hoursCode),
            groupName: closureMsgRow.groupName,
            description: closureMsgRow.description,
            type: closureMsgRow.type,
            category: closureMsgRow.category,
            startDate: moment(closureMsgRow.startDate).format("YYYY-MM-DD"),
            startTime: moment(closureMsgRow.startTime, "hh:mm A").format(
              "hh:mm A"
            ),
            endDate: moment(closureMsgRow.endDate).format("YYYY-MM-DD"),
            endTime: moment(closureMsgRow.endTime, "hh:mm A").format("hh:mm A"),
            startTimeZone: closureMsgRow.startTimeZone,
            endTimeZone: closureMsgRow.endTimeZone,
            ...closureMsgRow.languageMessage,
          };
    } else {
      let occurrences = null;
      for (let key in closureMsgRow.occurrences) {
        if (isSingleInstance && row.occurrenceId === key) {
          occurrences = {
            id: key,
            description: closureMsgRow.description,
            startDate: moment(closureMsgRow.startDate).format("YYYY-MM-DD"),
            startTime: closureMsgRow.startTime
              ? moment(closureMsgRow.startTime, "hh:mm A").format("hh:mm A")
              : moment(row.startTime, "hh:mm A").format("hh:mm A"),
            endDate: moment(closureMsgRow.endDate).format("YYYY-MM-DD"),
            endTime: closureMsgRow.endTime
              ? moment(closureMsgRow.endTime, "hh:mm A").format("hh:mm A")
              : moment(row.endTime, "hh:mm A").format("hh:mm A"),
            startTimeZone: closureMsgRow.startTimeZone,
            endTimeZone: closureMsgRow.endTimeZone,
            ...closureMsgRow.languageMessage,
          };
        }
      }

      if (repeatPattern && isSingleInstance) {
        const cloneRow = { ...row };
        cloneRow.hasOwnProperty("occurrences") && delete cloneRow.occurrences;
        const updatedRow = {
          ...cloneRow,
          ...closureMsgRow.languageMessage,
          languageMessage: closureMsgRow.languageMessage,
        };
        result = {
          ...updatedRow,
          updatedOccurrence: {
            ...occurrences,
          },
        };
      } else if (repeatPattern && !isSingleInstance) {
        result = {
          id: row.id,
          hoursCode: row.hoursCode,
          groupName: row.groupName,
          description: closureMsgRow.description,
          type: row.type,
          category: row.category,
          startDate: moment(closureMsgRow.startDate).format("YYYY-MM-DD"),
          startTime: closureMsgRow.startTime
            ? moment(closureMsgRow.startTime, "hh:mm A").format("hh:mm A")
            : moment(row.startTime, "hh:mm A").format("hh:mm A"),
          endDate: moment(closureMsgRow.endDate).format("YYYY-MM-DD"),
          endTime: closureMsgRow.endTime
            ? moment(closureMsgRow.endTime, "hh:mm A").format("hh:mm A")
            : moment(row.endTime, "hh:mm A").format("hh:mm A"),
          startTimeZone: closureMsgRow.startTimeZone,
          endTimeZone: closureMsgRow.endTimeZone,
          repeatPattern: repeatPattern,
          updateOccurrences:
            row.repeatPattern !== repeatPattern ||
            moment(row.startDate).format("YYYY-MM-DD") !==
              moment(closureMsgRow.startDate).format("YYYY-MM-DD") ||
            moment(row.endDate).format("YYYY-MM-DD") !==
              moment(closureMsgRow.endDate).format("YYYY-MM-DD") ||
            moment(row.startTime, "hh:mm A").format("hh:mm A") !==
              moment(closureMsgRow.startTime, "hh:mm A").format("hh:mm A") ||
            moment(row.endTime, "hh:mm A").format("hh:mm A") !==
              moment(closureMsgRow.endTime, "hh:mm A").format("hh:mm A") ||
            row.startTimeZone !== closureMsgRow.startTimeZone ||
            row.endTimeZone !== closureMsgRow.endTimeZone
              ? true
              : false,
          ...closureMsgRow.languageMessage,
        };
      } else {
        result = {
          id: row.id,
          hoursCode: row.hoursCode,
          groupName: row.groupName,
          description: closureMsgRow.description,
          type: row.type,
          category: row.category,
          startDate: moment(closureMsgRow.startDate).format("YYYY-MM-DD"),
          startTime: closureMsgRow.startTime
            ? moment(closureMsgRow.startTime, "hh:mm A").format("hh:mm A")
            : moment(row.startTime, "hh:mm A").format("hh:mm A"),
          endDate: moment(closureMsgRow.endDate).format("YYYY-MM-DD"),
          endTime: closureMsgRow.endTime
            ? moment(closureMsgRow.endTime, "hh:mm A").format("hh:mm A")
            : moment(row.endTime, "hh:mm A").format("hh:mm A"),
          startTimeZone: closureMsgRow.startTimeZone,
          endTimeZone: closureMsgRow.endTimeZone,
          ...closureMsgRow.languageMessage,
        };
      }
    }
    dispatch(createOrUpdateClosureMsg(result));
    toggleDialogOpen();
  };

  const handleClosureMsgRow = (updatedRow, isAddnew) => {
    if (updatedRow) {
      setClosureMsgRow(updatedRow);
      if (updatedRow.isAudioFileChanged) {
        setIsAudioChanged(true);
      }
      const startTime = moment(updatedRow.startTime, "hh:mm A", true);
      const endTime = moment(updatedRow.endTime, "hh:mm A", true);
      const sDate = moment(updatedRow.startDate).format("MM/DD/YYYY");
      const eDate = moment(updatedRow.endDate).format("MM/DD/YYYY");
      const momentStartDate = moment(updatedRow.startDate);
      const momentEndDate = moment(updatedRow.endDate);
      const updatedEndDateTime = `${eDate} ${updatedRow.endTime}`;
      const selectedTimeZoneDateTime = moment
        .tz(moment(), updatedRow.startTimeZone)
        .format("MM/DD/YYYY hh:mm A");

      const isValidupdatedEndDateTime = moment(updatedEndDateTime).isAfter(
        moment(selectedTimeZoneDateTime),
        "time"
      );
      const dateDiff = sDate === eDate ? 0 : 1;
      const isValidStartDate = moment(sDate).isValid();
      const isValidEndDate = moment(eDate).isValid();
      const isValidStartTime = startTime.isValid();
      const isValidEndTime = endTime.isValid();
      let hasLanguageMessage,
        validateStartEndTime,
        status = false;

      const rowLanguage =
        Object.keys(row).length > 0 &&
        Object.keys(row).filter((a) => a.includes("message"));

      const updatedRowLanguage =
        Object.keys(updatedRow).length > 0 &&
        Object.keys(updatedRow).filter((a) => a.includes("message"));

      const language =
        Object.keys(updatedRow).length > 0 &&
        Object.keys(updatedRow).find((a) => a.includes("message"));
      hasLanguageMessage = language ? true : false;

      if (
        (isValidStartTime &&
          isValidEndTime &&
          endTime.isSameOrAfter(startTime)) ||
        (isValidStartTime && isValidEndTime && dateDiff > 0)
      ) {
        validateStartEndTime = true;
      }

      if (
        (isAddnew && !hasLanguageMessage) ||
        !isValidStartDate ||
        !isValidEndDate ||
        momentEndDate.isBefore(momentStartDate) ||
        updatedRow.startTime === "" ||
        updatedRow.endTime === "" ||
        !isValidEndTime ||
        !isValidStartTime ||
        !validateStartEndTime ||
        !isValidupdatedEndDateTime ||
        updatedRow.description === "" ||
        updatedRow.category === ""
      ) {
        status = true;
      } else if (
        updatedRow.isAudioFileChanged ||
        isAudioChanged ||
        row.description !== updatedRow.description ||
        (row.endTimeZone !== updatedRow.endTimeZone &&
          isValidupdatedEndDateTime) ||
        ((moment(row.startDate).format("YYYY-MM-DD") !==
          moment(updatedRow.startDate).format("YYYY-MM-DD") ||
          moment(row.endDate).format("YYYY-MM-DD") !==
            moment(updatedRow.endDate).format("YYYY-MM-DD") ||
          moment(row.startTime, "hh:mm A").format("hh:mm a") !==
            moment(updatedRow.startTime, "hh:mm A").format("hh:mm a") ||
          moment(row.endTime, "hh:mm A").format("hh:mm a") !==
            moment(updatedRow.endTime, "hh:mm A").format("hh:mm a") ||
          rowLanguage.length !== updatedRowLanguage.length ||
          row.repeatPattern !== updatedRow.repeatPattern) &&
          !isAddnew)
      ) {
        status = false;
      } else {
        status = true;
      }
      if (!hasLanguageMessage) {
        status = true;
      }
      status ? setButtonDisabled(true) : setButtonDisabled(false);
    }
  };

  const dialogConfig = {
    title,
  };

  return (
    <>
      <SbuxDialog2
        open={open}
        config={dialogConfig}
        handleOnClose={toggleDialogOpen}
      >
        <DialogContent dividers>
          {isAddNew ? (
            <ClosureMsgAdd
              business={business}
              languages={languages}
              row={row}
              handleClosureMsgRow={handleClosureMsgRow}
              globalConfigUi={globalConfigUi}
            />
          ) : (
            <ClosureMsgEdit
              row={closureMsgRow}
              handleClosureMsgRow={handleClosureMsgRow}
              globalConfigUi={globalConfigUi}
              isSingleInstance={isSingleInstance}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Box className="actionContainer">
          {!isAddNew && (
            <Button size="medium"
              onClick={handleDeleteOnClick}
              variant={row.status !== "Expired" ? "contained" : "outlined"}
              disabled={row.status === "Expired"}
              color={row.status !== "Expired" ? "complementary" : "primary"}
            >
              <SbuxTranslate>{"Delete"}</SbuxTranslate>
            </Button>
          )}

          <Button
            onClick={handleSave}
            variant="contained" size="medium"
            disabled={buttonDisabled || row.status === "Expired"}
          >
            <SbuxTranslate>{"Save"}</SbuxTranslate>
          </Button>

          <Button variant="outlined" size="medium"
            onClick={toggleDialogOpen}>
            <SbuxTranslate>{"Cancel"}</SbuxTranslate>
          </Button>
          </Box>
        </DialogActions>
      </SbuxDialog2>

      {openSnackBar && (
        <SbuxSnackbars
          width={380}
          open={openSnackBar}
          anchorOriginHorizontal={`center`}
          message={""}
          severity={SNACK_BARS_SEVERITY_ERROR}
          handleClose={handleClose}
        />
      )}

      {isDelete && (
        <ClosureSingleDeleteDialog
          row={row}
          title={`Delete Closure Message`}
          name={row.description}
          open={isDelete}
          isSingleInstance={isSingleInstance}
          toggleDialogOpen={toggleDialogOpen}
          toggleCancelDialog={handleDeleteOnClick}
        />
      )}
    </>
  );
};

export default ClosureMsgDialog;
