import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Autocomplete,
} from "@mui/material";
import { MEDIA_FILE_DOES_NOT_EXISTS } from "../../../../constants";

const MediafileAutoComplete = ({
  data,
  handleSelectChange = null,
  value,
  disabled,
  required,
  label = null,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState(null);
  const {
    mediaMgt: { mediaMgtData },
  } = useSelector((state) => state);

  useEffect(() => {
    setOptions(data);
    setSelectedValue(null);
  }, [data]);

  useEffect(() => {
    if (value) {
      const result = mediaMgtData.data.find((item) => item.filePath === value);
      setSelectedValue({
        name: result.fileName,
        value,
      });
    }
  }, [value]);

  const handleHighlightChange = (event, option, reason) => {
    if (option && reason === "keyboard") {
      setSelectedValue(option);
    }
  };
  const handleOnChange = (event, newValue) => {
    const selected = newValue?.value ?? null;
    setSelectedValue(selected);
    handleSelectChange(selected);
  };
  const handleClearButtonClick = () => {
    setSelectedValue(null); // Clear the selected value
    handleSelectChange(null);
  };

  return (
    <Autocomplete
      size="small"
      label={label}
      disableClearable
      disablePortal
      options={!options ? [{ name: "loading...", value: "" }] : options}
      autoHighlight
      noOptionsText={
        <Box sx={{ fontSize: 14 }}>{MEDIA_FILE_DOES_NOT_EXISTS}</Box>
      }
      disabled={disabled}
      required={true}
      value={selectedValue}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onHighlightChange={handleHighlightChange}
      onChange={handleOnChange}
      ListboxProps={{
        sx: { fontSize: 13 },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component={"li"} {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <Box sx={{ position: "relative" }}>
          <TextField
            {...params}
            size="small"
            label={label}
            required={required}
          />
          {selectedValue && (
            <IconButton
              className="clearButton"
              disableFocusRipple
              disableRipple
              onClick={handleClearButtonClick}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      )}
    />
  );
};

export default MediafileAutoComplete;
