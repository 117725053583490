import { makeStyles } from "@mui/styles";
import { COLOR_LIGHT_RED } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    minWidth: 400,
    minHeight: 20,
    paddingBottom: 2,
  },
  dialogContentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  dialogTempMessage: {
    width: "350px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  dialogMedia: {
    width: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  dialogTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    marginTop: -32,
    width: "85%",
  },
  dialogContent: {
    padding: 20,
    fontSize: 16,
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
    paddingRight: 5,
    gap: "10px",
    "& > button": {
      maxWidth: 130,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  },
  dialogButtons: {
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "flex-end",
    gap: "10px",
    "& > button": {
      maxWidth: 130,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  },
  dialogButton: {
    marginRight: 10,
    fontSize: 14,
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  cellFocus: {
    cursor: "pointer",
    width: "20vw",
  },
  stopContactCell: {
    cursor: "pointer",
    width: "20vw",
    minWidth: 100,
    flex: 1,
  },
  resize: {
    fontSize: 16,
  },
  datePicker: {
    padding: 4,
    width: 120,
    height: 31,
    borderColor: theme.palette.black.border,
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 14,
  },
  timePicker: {
    padding: 4,
    width: 90,
    height: 26,
  },
  recDatePicker: {
    width: 120,
    height: 26,
  },
  boderColor: {
    border: `1px solid ${COLOR_LIGHT_RED}`,
  },
  disabledBox: {
    pointerEvents: "none",
    opacity: 0.5,
  },

  megiaMgtRowContainer: {
    display: "flex",
    gap: 10,
    "& > button": {
      width: "auto",
    },
  },

  formControl: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    "& > .MuiBox-root": {
      maxWidth: "350px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "100%",
      },
    },
    "& > .MuiBox-root.fullWidth": {
      maxWidth: "100%",
    },
  },

  topDiv: {
    marginTop: 8,
  },

  textToSpeachActionContainer: {
    marginLeft: "10px",
    marginBottom: "10px",
    display: "flex",
    gap: 10,
    "& > button": {
      width: "auto",
    },
  },

  timePicker: {
    display: "flex",
    justifyContent: "space-between",
    "& > .MuiBox-root.last": {
      width: "50%",
      maxWidth: "315px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "100%",
        marginTop: 15,
      },
    },
    "& > .bold": {
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
    },
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "normal",
    },
  },

  tabContainer: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.black.border,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tabContent: {
    margin: 10,
  },
  repeatPatternContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: 20,
  },
  audioFileContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "5px 10px",
    "& > .MuiBox-root:first-child": {
      height: "70px",
    },
    "& > .player": {
      fontSize: 13,
      marginTop: "8px",
      display: "flex",
      justifyContent: "center",
    },
  },
  deleteButton: {
    cursor: "pointer",
    color: theme.palette.redAlert[800],
    fontSize: 16,
    marginTop: -0.5,
    height: 18,
    padding: "0",
    paddingLeft: "5px",
  },

  inputRoot: {
    marginBottom: -1.5,
    marginTop: -1,
    marginLeft: -0.5,
    width: "100%",
    "@media (min-width: 1023px)": {
      "& .MuiTextField-root": {
        width: "50ch",
      },
    },
  },

  selectTable: {
    marginRight: 10,
    fontSize: 16,
    fontWeight: "bold",
  },
  textField: {
    fontSize: 14,
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
  },
  formControl: {
    flex: 1,
    marginTop: 1,
  },
  cellCheckbox: {
    "& .MuiSvgIcon-root": { fontSize: 22 },
    color: "#006241",
    "&.Mui-checked": {
      color: "#006241",
    },
  },
  expandablerow: {
    "& > *": {
      borderBottom: "unset",
      backgroundColor: (props) => (props.isHighlighted ? "#cba258" : "unset"),
    },
  },
  iconButton: {
    "&:focus": {
      outline: 0,
    },
    "&.MuiIconButton-root": {
      padding: 0,
      paddingLeft: "5px",
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 600,
    paddingBottom: 4,
  },
  mediaDialogTabs: {
    marginTop: 1,
    width: "100%",

    "& > .tabContainer": {
      height: 300,
      border: `1px solid ${theme.palette.black.border}`,
      borderRadius: 8,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 4,
    },
  },
  warningText: {
    fontSize: 13,
    color: theme.palette.primary.main,
    fontWeight: 600,
    paddingBottom: 0.5,
  },
  t2sAudioPlayer: {
    textAlign: "center",
    marginTop: 10,
    "& > audio": {
      height: 30,
    },
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    gap: "10px",
    "& > button": {
      maxWidth: 130,
      width: 130,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "100%",
      },
    },
  },
  customFilterForm: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
}));

export default useStyles;
