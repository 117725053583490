import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    fontSize: 14,
    outline: "0 !important",
    textTransform: "capitalize",
    height: "26px",
    backgroundColor: theme.palette.darkGray[200],
    "&:hover": {
      color: theme.palette.complementary.contrastText,
      backgroundColor: theme.palette.darkGray.main,
    },
    "& > .active": {
      color: theme.palette.complementary.contrastText,
      backgroundColor: theme.palette.darkGray.main,
    },
    flex: 1,
    textWrap: "nowrap",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottom: 0,
    marginRight: 2,
  },
  active: {
    color: theme.palette.complementary.contrastText,
    backgroundColor: theme.palette.darkGray.main,
  },
  disabled: {
    cursor: "not-allowed",
  }
}));

export default useStyles;
