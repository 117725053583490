import { Auth } from "aws-amplify";
import { logMessage } from "../../utils/amplifyLogger";
import { setAgentStateOffline } from "../../utils/agentStateChangeUtil";
import { logOutOfCcp } from "../../services/auth";
import "amazon-connect-streams";

export const setAgentOffline = async (
  screenName = "Idle Timer",
  instanceUrl,
  userEmail
) => {
  await setAgentStateOffline(screenName);
  await logOutOfCcp(instanceUrl, "SbuxIdleTimer", userEmail);
};

export const setLogout = async (
  screenName = "Idle Timer",
  instanceUrl,
  userEmail
) => {
  try {
    await setAgentOffline(screenName, instanceUrl, userEmail);

    setTimeout(async () => {
      await Auth.signOut({ global: true });
    });
  } catch (err) {
    logMessage(screenName, `Signout failure. ${err}`, `error`);
  }
};
