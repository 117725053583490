import React, { useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PauseIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import StopCircleIcon from "@mui/icons-material/StopCircleOutlined";
import {
  CONTACT_CONNECTED_STATUS,
  CONTACT_RECORDING_PAUSE,
  CONTACT_RECORDING_START,
  CONTACT_RECORDING_RESUME,
  CONTACT_RECORDING_STOP,
  COLOR_GOLD,
  COLOR_GREEN_ACCENT,
  VAR_INITIAL_CONTACT_ID,
  VAR_CONTACT_ID,
  SNACK_BARS_SEVERITY_ERROR,
} from "../../../constants";
import SbuxTooltip from "../../../components/SbuxTooltip";
import SbuxTranslate from "../../../components/SbuxTranslate";
import SbuxConfirmationDialog from "../../../components/SbuxConfirmationDialog";
import IconButton from "@mui/material/IconButton";
import SbuxSnackbars from "../../../components/SbuxSnackbars";
import useStyles from "./styles";
import { useTheme } from "@mui/material/styles";

const ScreenRecordingButtons = ({
  handleRecordingButtonTrigger,
  ccpContactStatus,
  contactAttributes,
  contactRecordingState,
  loading,
  error,
  hideErrorDialog,
  isStopRecordingFeatureImplementedInAWS,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  if (!!error) {
    hideErrorDialog();
  }

  const isStartOrPauseRecordingButtonDisabled = () => {
    return contactRecordingState === CONTACT_RECORDING_STOP || loading;
  };
  const isStopRecordingButtonDisabled = () => {
    return (
      contactRecordingState === null ||
      contactRecordingState === CONTACT_RECORDING_STOP ||
      loading
    );
  };

  // const getBackgroundColor = () => {
  //   return [CONTACT_RECORDING_START, CONTACT_RECORDING_RESUME].includes(
  //     contactRecordingState
  //   )
  //     ? COLOR_GOLD
  //     : COLOR_GREEN_ACCENT;
  // };

  const pauseContactRecordingAndThenStop = async (
    contactId,
    initialContactId
  ) => {
    if (
      [CONTACT_RECORDING_PAUSE, CONTACT_RECORDING_PAUSE, null].includes(
        contactRecordingState
      )
    ) {
      return;
    }
    await handleRecordingButtonTrigger({
      action: CONTACT_RECORDING_PAUSE,
      contactId,
      initialContactId,
    });
  };

  const handleButtonClick = async (action) => {
    if (ccpContactStatus !== CONTACT_CONNECTED_STATUS) {
      return;
    }
    const initialContactId =
      contactAttributes?.[VAR_INITIAL_CONTACT_ID]?.value ?? "";
    const contactId = contactAttributes?.[VAR_CONTACT_ID]?.value ?? "";
    if (!contactId || !initialContactId) {
      return;
    }
    const allowedActions = getAllowedActions();
    if (!allowedActions.includes(action)) {
      return;
    }
    if (
      action === CONTACT_RECORDING_STOP &&
      !isStopRecordingFeatureImplementedInAWS
    ) {
      await pauseContactRecordingAndThenStop(contactId, initialContactId);
    }
    await handleRecordingButtonTrigger({ action, contactId, initialContactId });
  };

  const getAllowedActions = () => {
    let allowedActionsList = [];
    switch (contactRecordingState) {
      case CONTACT_RECORDING_START:
        allowedActionsList = [CONTACT_RECORDING_PAUSE, CONTACT_RECORDING_STOP];
        break;
      case CONTACT_RECORDING_PAUSE:
        allowedActionsList = [CONTACT_RECORDING_RESUME, CONTACT_RECORDING_STOP];
        break;
      case CONTACT_RECORDING_RESUME:
        allowedActionsList = [CONTACT_RECORDING_PAUSE, CONTACT_RECORDING_STOP];
        break;
      case CONTACT_RECORDING_STOP:
        allowedActionsList = [];
        break;
      default:
        allowedActionsList = [CONTACT_RECORDING_START, CONTACT_RECORDING_STOP];
        break;
    }
    return allowedActionsList;
  };

  const getAvailableActionForStartButton = () => {
    let action;
    switch (contactRecordingState) {
      case CONTACT_RECORDING_START:
        action = CONTACT_RECORDING_PAUSE;
        break;
      case CONTACT_RECORDING_PAUSE:
        action = CONTACT_RECORDING_RESUME;
        break;
      case CONTACT_RECORDING_RESUME:
        action = CONTACT_RECORDING_PAUSE;
        break;
      default:
        action = CONTACT_RECORDING_START;
        break;
    }
    return action;
  };

  const isStartOrPauseRecordingButtonLoading = () => {
    return (
      loading &&
      [
        CONTACT_RECORDING_START,
        CONTACT_RECORDING_PAUSE,
        CONTACT_RECORDING_RESUME,
      ].includes(contactRecordingState)
    );
  };

  const isStopRecordingButtonLoading = () => {
    return loading && contactRecordingState === CONTACT_RECORDING_STOP;
  };

  const toggleDialogOpen = (e) => {
    setShowConfirmationDialog((value) => !value);
  };

  const getTooltipForStartButton = () => {
    const action = getAvailableActionForStartButton();
    if (contactRecordingState === CONTACT_RECORDING_STOP) {
      return `Recording was stopped and cannot be resumed`;
    }
    return `Click to ${action} recording`;
  };

  const getStartPauseOrResumeIcon = () => {
    const isDisabled = isStartOrPauseRecordingButtonDisabled();
    if (
      [CONTACT_RECORDING_START, CONTACT_RECORDING_RESUME].includes(
        contactRecordingState
      )
    ) {
      return (
        <PauseIcon
          sx={{
            color: isDisabled ? "secondary" : theme.palette.common.black,
            fontSize: 24,
          }}
        />
      );
    }
    return (
      <FiberManualRecordIcon
        sx={{
          color: isDisabled ? "secondary" : theme.palette.redAlert.main,
          fontSize: 24,
        }}
      />
    );
  };

  const renderButtons = () => {
    return (
      <>
        {error && (
          <SbuxSnackbars
            open={!!error}
            message={error}
            severity={SNACK_BARS_SEVERITY_ERROR}
          />
        )}
        <SbuxTooltip
          title={<SbuxTranslate>{getTooltipForStartButton()}</SbuxTranslate>}
          disableFocusListener
          disableTouchListener
        >
          <div>
            <IconButton
              id="screenRecordingStart"
              className={classes.button}
              onClick={() => {
                handleButtonClick(getAvailableActionForStartButton());
              }}
              disableFocusRipple
              disableRipple
              disabled={isStartOrPauseRecordingButtonDisabled()}
              loading={isStartOrPauseRecordingButtonLoading()}
            >
              {getStartPauseOrResumeIcon()}
            </IconButton>
          </div>
        </SbuxTooltip>
        <SbuxTooltip
          title={
            <SbuxTranslate>
              {contactRecordingState === CONTACT_RECORDING_STOP
                ? "Recording was stopped and cannot be resumed"
                : "Click to stop recording entirely"}
            </SbuxTranslate>
          }
        >
          <div>
            <IconButton
              id="screenRecordingStop"
              className={`${classes.button} ${classes.red}`}
              onClick={(e) => setShowConfirmationDialog(true)}
              disableFocusRipple
              disableRipple
              disabled={isStopRecordingButtonDisabled()}
              loading={isStopRecordingButtonLoading()}
            >
              <StopCircleIcon
                sx={{
                  color: isStopRecordingButtonDisabled()
                    ? "secondary"
                    : theme.palette.common.black,
                  fontSize: 24,
                }}
              />
            </IconButton>
          </div>
        </SbuxTooltip>
        <SbuxConfirmationDialog
          open={showConfirmationDialog}
          toggleDialogOpen={toggleDialogOpen}
          handleOkClick={() => {
            handleButtonClick(CONTACT_RECORDING_STOP);
            toggleDialogOpen();
          }}
          heading="Confirm Stop Recording"
          content="Are you sure you want to stop this recording? This recording cannot be restarted once it is stopped."
        />
      </>
    );
  };

  return ccpContactStatus === CONTACT_CONNECTED_STATUS ? renderButtons() : null;
};

export default ScreenRecordingButtons;
